<template>
  <div class="table">
    <div class="container">
      <div class="handle-box">
        <el-button
          type="success"
          icon="el-icon-plus"
          class="handle-add mr10"
          @click="onAdd"
        >
          添加
        </el-button>
        <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
          刷新
        </el-button>
        <el-input
          style="width: 220px; margin-right: 20px"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">
          搜索
        </el-button>
        <!-- <el-button type="primary" icon="el-icon-search" @click="devSave">
          添加部门
        </el-button> -->
      </div>
      <!--数据表格-->
      <el-table
        v-loading="listLoading"
        element-loading-text="正在加载 ..."
        :data="list"
        row-key="id"
        border
        fit
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="部门名称"></el-table-column>
        <el-table-column prop="description" label="备注"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="500px"
      center
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          class="demo-dynamic"
        >
          <el-form-item label="上级部门" prop="pid">
            <el-cascader
              v-model="form.pid"
              :options="options"
              clearable
              :show-all-levels="false"
              :props="{
                value: 'id',
                label: 'name',
                children: 'children',
                emitPath: false,
                checkStrictly: true,
              }"
              placeholder="请选择上级部门"
            ></el-cascader>
            <span class="demo-tip">不填默认为一级部门</span>
          </el-form-item>
          <el-form-item
            label="部门名称"
            prop="name"
            :rules="[
              { required: true, trigger: 'blur', message: '请输入部门名称' },
            ]"
          >
            <el-input v-model="form.name" type="text" clearable />
          </el-form-item>
          <el-form-item label="备注" prop="description">
            <el-input v-model="form.description" type="text" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDlgShow = false">取 消</el-button>
        <!-- <el-button size="small" type="primary" @click="submitForm('form')"> -->
        <el-button size="small" type="primary" @click="save">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDepartmentList,
  saveDepartment,
  delDepartment,
} from "@/api/admin/department";

export default {
  name: "Department",
  data() {
    return {
      options: [],
      list: [],
      form: {
        id: "",
        name: "",
        pid: "",
        description: "",
      },
      filterText: "",
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 递归搜索关键字
    mapTree(value, arr) {
      let that = this;
      let newarr = [];
      arr.forEach((element) => {
        if (element.name.indexOf(value) > -1) {
          // 判断条件
          newarr.push(element);
        } else {
          if (element.children && element.children.length > 0) {
            let redata = that.mapTree(value, element.children);
            if (redata && redata.length > 0) {
              let obj = {
                ...element,
                children: redata,
              };
              newarr.push(obj);
            }
          }
        }
      });
      return newarr;
    },
    search() {
      let arr = this.mapTree(this.filterText, this.list);
      this.list = arr;
      console.log("arr", arr);
    },
    loadDepartmentList() {
      this.options = [];
      getDepartmentList().then((response) => {
        this.options = response.data;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onRefresh() {
      this.fetchData();
    },
    onAdd() {
      (this.form = {
        id: "",
        name: "",
        pid: "",
        description: "",
      }),
        this.loadDepartmentList();
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        pid: row.pid,
        description: row.description,
      };
      this.loadDepartmentList();
      this.selectDlgShow = true;
    },
    onDel(row) {
      this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDepartment({ id: row.id })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error("删除错误！");
          });
      });
    },
    fetchData() {
      this.listLoading = true;
      getDepartmentList().then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
          // this.devSave();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveDepartment(this.form)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    // devSave() {
    //   // liujiang
    //   if (n > liujiang.length - 1) {
    //     this.$message({
    //       message: "添加结束",
    //       type: "success",
    //     });
    //     this.fetchData();
    //     return;
    //   }
    //   let data = {
    //     pid: "1181048431795458050",
    //     name: liujiang[n],
    //   };
    //   saveDepartment(data)
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         n++;
    //         this.devSave();
    //       } else {
    //         this.$message.error("保存失败");
    //       }
    //     })
    //     .catch(() => {
    //       this.dlgLoading = false;
    //     });
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.demo-tip {
  font-size: 12px;
  color: gray;
  margin-left: 10px;
}
</style>
