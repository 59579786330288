import request from '@/utils/request';

export function getDepartmentList(data) {
    return request({
        url: '/department/getList',
        method: 'post',
        data
    });
}
export function getTreeList(params) {
    return request({
        url: '/meituanDepartmentStatistics/treeList',
        method: 'get',
        params
    });
}



export function saveDepartment(data) {
    return request({
        url: '/department/save',
        method: 'post',
        data
    });
}

export function delDepartment(data) {
    return request({
        url: '/department/del',
        method: 'post',
        data
    });
}